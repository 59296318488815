import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Landingpage from "../components/templates/landingpage"
import SEO from "../components/atoms/seo"
import Title from "../components/atoms/title"
import Divider from "../components/atoms/divider"
import Box from "../components/atoms/box"
import Line from "../components/atoms/line"
import Card from "../components/atoms/card"
import More from "../components/atoms/more"
import LogotypeProduct from "../components/atoms/LogotypeProduct"
import Button from "../components/atoms/button"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteThemeColor {
      site {
        siteMetadata {
          title
          primary_color,
          secondary_color
        }
      }
    }
  `)

  return (
    <Landingpage title='Home' primaryColor={data.site.siteMetadata.primaryColor}>
        <SEO 
          title="Atropos Intelligence Inc. We utilize our unique platform and processes."
          description="Welcome to Atropos Intelligence Inc. We utilize our unique platform and processes to provide intelligence and insight that improves internet marketplaces."
          keywords="atropos, atropos intelligence, ismail vali, ismail vali ceo, ismail vali yield sec, yield sec, yieldsec, ismail vali atropos intelligence"
        />

        <Box color='aqua'>
          <Divider />
          <Divider />

          <Title level={1} color={'#12122c'}>What we do</Title>
          <Line />
          <p style={{color: '#12122c', maxWidth: '800px', margin: '1rem auto'}}>
            Through our platform, products, processes and people, 
            Atropos Intelligence optimize and protect online marketplaces.
          </p>
          
          <p style={{color: '#12122c', maxWidth: '800px', margin: '1rem auto'}}>
            Built from a military counterinsurgency stack, we utilize our unique platform, 
            expert personnel and AI and machine-learning processes to provide intelligence 
            and insight that improves internet marketplaces and protects them from criminal interference.
          </p>

          <p style={{color: '#12122c', maxWidth: '800px', margin: '1rem auto'}}>
            Across jurisdictions we assist governments, regulators, 
            legal industry operators and suppliers, brands and media & 
            content platforms to identify and remove illegal influence, enabling:
          </p>

          <div style={{color: '#12122c', maxWidth: '800px', margin: '1rem auto'}}>
            <ul>
              <li>effective consumer participation and protection</li>
              <li>fully realized revenues for legal industry operators</li>
              <li>fair and unimpaired equity values for legal industry operators and suppliers</li>
              <li>optimal receipt of taxation and other government revenue streams</li>
              <li>delivery of detailed reporting, dashboards and evidence to support law enforcement and other stakeholders</li>
            </ul>
          </div>
          
          <p style={{color: '#12122c', maxWidth: '800px', margin: '1rem auto'}}>
            Our platform, products, processes and people present the right 
            framework for government, regulator, industry and stakeholders 
            to make decisions & take actions from - the decisions and 
            actions that protect and perfect valuable and at-risk online 
            marketplaces and their consumers, today.
          </p>

          <Divider />
          <Divider />
        </Box>

        <Box id="products">
          <Divider />
          <Title level={1}>Products</Title>
          <Line />
          <p style={{color: '#12122c', maxWidth: '800px', margin: '1rem auto'}}>
            Atropos Intelligence Inc. operate a technical intelligence platform, 
            built from a military stack that has been tried, tested and proven 
            across anti-terrorism and counterinsurgency operations, worldwide. 
            Through our work for clients, we liken our mission to countering a 
            commercial insurgency - the one represented by the black market and 
            criminal interference across valuable but at-risk online marketplaces.
          </p>

          <Divider />
          <Divider />

          <Card variant="yieldsec">
            <LogotypeProduct variant='yieldsec' />
            <p>
              Yield Sec works across the betting, gaming and lottery marketplace 
              on a jurisdiction by jurisdiction basis, worldwide. The Yield Sec 
              platform features products and advisory services for government, 
              regulator, legal operators, media and content platforms & other 
              stakeholders working towards an essential goal:  The provision 
              of an identified, protected and optimized marketplace for online 
              betting, gaming and lotteries by removing black-market influence 
              and instability to create future sustainability.
            </p>
            <More link='https://www.yieldsec.com/' target="_blank">Read more</More>
          </Card>
          <Card>
            <Title level={2}>Pipeline</Title>
            <p>
              Atropos Intelligence have interests across multiple 
              industry verticals. We are presently at work on 
              products across further valuable online marketplaces 
              that suffer from black market and criminal interference.
            </p>
            <More link={'mailto:info@yieldsec.com?Subject=Contact%20from%20Atropos%20website'}>Contact Us</More>
          </Card>
        </Box>

        <Box color='grey' id="results">
          <Divider />

          <Title level={1} color={'#12122c'}>Results</Title>
          <Line />

          <div style={{color: '#12122c', maxWidth: '800px', margin: '1rem auto'}}>
            <p>
              What results can clients expect from our solutions 
              that help drive the right decisions and outcomes?
            </p>

            <Title level={4} position='left' style={{maxWidth: '800px', margin: '1rem auto'}}>
              A contained, compliant commercial marketplace
            </Title>
            <p>
              A truly level playing field for legal operators with a meaningfully 
              safe and fair experience for consumers. Atropos Intelligence’s platform, 
              products, processes and people combine to produce the most comprehensive 
              view of the Total Addressable Marketplace possible, allowing for 
              the discovery, disruption and disconnection of criminal efforts 
              to cheat, deceive and steal revenue and taxation, whilst abusing 
              customers and marketplace conditions.  There is simply no 
              manual fact-finding, fact-checking, decision-enabling match 
              for the abilities of the Atropos Intelligence platform.
            </p>
            
            <Title level={4} position='left' style={{maxWidth: '800px', margin: '1rem auto'}}>
              Predictable legal operator revenues and tax yield
            </Title>
            <p>
              No more black-market revenue and tax theft. A marketplace assisted by 
              Atropos Intelligence is a sustainable marketplace, populated only by 
              legal operators, who can achieve growth from positive competition and 
              placing primacy upon improved customer experiences, with safety and sustainability throughout.
            </p>
            
            <Title level={4} position='left' style={{maxWidth: '800px', margin: '1rem auto'}}>
              Meaningful customer protection
            </Title>
            <p>
              Consumers can only be sincerely protected when the marketplace 
              is effectively protected, first. The exclusion of illegal 
              influence and interference enables consumer protection and 
              social responsibility measures to be uniformly applied among 
              a contained group of brands and operators.
            </p>
            
            <Title level={4} position='left' style={{maxWidth: '800px', margin: '1rem auto'}}>
              Why do marketplaces need Atropos Intelligence?
            </Title>
            <p>
              When consumers want to find and transact for goods, 
              services and experiences, they should find responsible 
              brands and operators providing fair, safe and reliable online commerce.
            </p>
     
            <p>
              Today, in many markets, illegal influence, scams and 
              piracy are widespread and negatively impact upon the 
              many legal stakeholders to each marketplace.
            </p>

            <p style={{marginBottom: '8px'}}>
              Atropos Intelligence monitors and protects marketplaces so that:
            </p>
            <ul>
              <li>Consumers don’t need to check before they buy</li>
              <li>Legal operators & suppliers gain the revenues they should</li>
              <li>Legal operators & suppliers maintain the reputation & fair market equity value they should</li>
              <li>State & National governments gain the taxation they should</li>
              <li>Law enforcement can pursue unpaid taxation & other revenues</li>
            </ul>
            <p>
              Online marketplaces should not fund crime. They should fund our commerce & communities.
            </p>

            <p style={{marginBottom: '8px'}}>
              Atropos Intelligence enables effective marketplaces that are:
            </p>
            <ul>
              <li>Optimized</li>
              <li>Protected</li>
            </ul>
          </div>
        </Box>

        <Box color="blue" id="contact">
          <Divider />
          <Title level={1} color="#fff">Contact Us</Title>
          <Line color="#fff" />
          <p style={{color: '#fff', maxWidth: '800px', margin: '1rem auto'}}>
            Get in touch to arrange for a demonstration of Yield Sec or 
            a discussion around our pipeline products across marketplace 
            verticals that are impacted by criminal interference.
          </p>

          <div style={{maxWidth: '800px', margin: '2rem auto'}}>
            <Button
                position="center"
                background={'#65ffcb'}
                hoverBackground={'#60F2C1'}
                color={'#12122c'}
                link={'mailto:info@yieldsec.com?Subject=Contact%20from%20Atropos%20website'}
            >
                Contact us
            </Button>
          </div>
        </Box>
    </Landingpage>
  )
}

export default IndexPage
