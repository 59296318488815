import React from "react"
import styled from 'styled-components';

const StyledContainer = styled.div`
    overflow: hidden;
    position: relative;
    margin: 0 auto 2rem;
    border: 0.1rem solid ${props => props.background ? props.background : 'rgba(10,0,75,.1)'};
    border-radius: 1.3rem;
    background: ${props => props.background ? props.background : 'transparent'};
    color: ${props => props.color ? props.color : '#12122c'};
    transition: background .5s;

    @media only screen and (min-width: 800px) {
        width: 480px;
    }
`;

const StyledContainerInner = styled.div`
    padding: 1.5rem;
    
    @media only screen and (min-width: 800px) {
        padding: 5rem;
    }
`;

const Card = ({ variant, children, ...props }) => {
    const background = {
        'yieldsec': '#1286c8'
    };

    const color = {
        'yieldsec': '#fff'
    }

    return (
        <StyledContainer background={background[variant]} color={color[variant]} {...props}>
            <StyledContainerInner>
                {children}
                
            </StyledContainerInner>
        </StyledContainer>
    );
}

export default Card