import React from "react"
import PropTypes from "prop-types"

import Header from "../organisms/header"
import Footer from "../organisms/footer"
import Title from "../atoms/title"
import Box from "../atoms/box"
import Button from "../atoms/button"
import Divider from "../atoms/divider"

import useSticky from '../hooks/Sticky'

import "../layout.css"

const Landingpage = ({headerImage, children}) => {
    const { isSticky, element } = useSticky()

    return (
        <>
            <Header headerImage={headerImage} landingpage={true} sticky={isSticky}>
                <Box style={{textAlign: 'center'}}>
                    <Divider />
                    <Divider />
                    <Divider />
                    <Divider />

                    <Title level='hero' page='firstpage' color='#fff' position='left' element={element}>
                        Online Marketplaces: <br />
                        Optimized & Protected.
                    </Title>

                    <Button
                        background={'#65ffcb'}
                        hoverBackground={'#60F2C1'}
                        color={'#12122c'}
                        link={'mailto:info@yieldsec.com?Subject=Contact%20from%20Atropos%20website'}
                    >
                        Contact us
                    </Button>
                </Box>
            </Header>
            <main>
                {children}
            </main>
            <Footer />
        </>
    )
}

Landingpage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Landingpage
