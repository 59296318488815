import React from "react"
import styled from "styled-components"

const StyledMore = styled.a`
    text-decoration: none;
    background: #65ffcb;
    color: #12122c;
    font-family: 'IIVorkursBold', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    border-radius: 100px;
    cursor: pointer;
    padding: .5rem 1rem;
    text-align: center;
    display: block;
    margin: 0 auto;
    width: 200px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    &:visited,
    &:focus,
    &:active,
    &:focus,
    &:focus-within,
    &:link:active,
    &:visited:active {
        color: #12122c;
    }

    &:hover {
        color: #12122c;
        box-shadow: 1px 2px 16px #65ffcb;
    }
`;

const More = ({ link, target, children }) => {
    const rel = target ? 'noopener noreferrer' : ''
    return (
        <StyledMore href={link} target={target} rel={rel}>
            {children}
        </StyledMore>
    )
}

export default More