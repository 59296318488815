import React from "react"
import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 100%;
    height: 4px;
    margin: 48px auto;

    @media only screen and (max-width: 600px) {
        width: 280px;
    }
`;

const StyledLine = styled.div`
    width: 200px;
    height: 4px;
    background: ${props => props.color ? props.color : '#12122c'};
    margin: 0 auto;
`;

const Line = ({ color, ...props }) => (
    <StyledContainer {...props}>
        <StyledLine color={color} />
    </StyledContainer>
)

export default Line