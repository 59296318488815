import React from 'react'
import styled from "styled-components"

import YieldSec from './YIELD_SEC_2_White.png'

const StyledImage = styled.img`
    margin: 0;
    padding: 0;
    max-width: 260px;
`;

const LogotypeProduct = ({variant}) => {
    let image = null;

    switch(variant) {
        case 'yieldsec':
            image = YieldSec;
            break;
        default:
            image = '';
            break;
    }

    return (
        <StyledImage src={image} />
    )
}

export default LogotypeProduct
