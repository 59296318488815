import React from "react"
import styled from "styled-components"

const StyledButton = styled.a`
    text-decoration: none;
    background: ${props => props.background};
    color: ${props => props.color};
    font-family: 'IIVorkursBold', sans-serif;
    font-weight: 900;
    font-size: 20px;
    text-transform: uppercase;
    border-radius: 100px;
    cursor: pointer;
    padding: 1rem;
    text-align: center;
    display: block;
    margin: ${props => props.position === 'center' ? '16px auto' : '16px 0'};
    width: 240px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    &:visited,
    &:focus,
    &:active,
    &:focus,
    &:focus-within,
    &:link:active,
    &:visited:active {
        color: #fff;
    }

    &:hover {
        color: ${props => props.color};
        box-shadow: inset 5px 5px 10px #51CCA2, 
            1px 6px 22px #65ffcb;
    }
`;

const Button = ({background, hoverBackground, color, position, link, target, children}) => {
    const rel = target ? 'noopener noreferrer' : ''
    return (
        <StyledButton background={background} hoverBackground={hoverBackground} color={color} href={link} position={position} target={target} rel={rel}>
            {children}
        </StyledButton>
    )
}

export default Button